<template>
  <form class="wrapper" @submit.prevent="save" ref="form">
    <div class="priority-changer">
      <img
        src="../../resource/img/modal/close2.svg"
        alt="close"
        @click="close"
        class="close-img"
      />
      <div class="priority__wrapper">
        <div>Приоритет</div>
        <div class="position-relative">
          <b-form-select
            class="choose"
            v-model="currentPriority"
            :options="orderPriorityOptions"
            value-field="value"
            text-field="text"
          ></b-form-select>

          <div class="button__container">
            <button>Сохранить</button>
            <button @click="close">Отмена</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { ORDER_PRIORITY_OPTIONS } from "@/app-config";

export default {
  name: "ChangePriorityModal",
  props: ["priority"],
  data() {
    return {
      currentPriority: null,
      orderPriorityOptions: ORDER_PRIORITY_OPTIONS
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },

    save() {
      this.$emit("save", this.currentPriority);
    }
  },
  mounted() {
    this.currentPriority = this.priority;
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.priority__wrapper {
  width: 350px;
  margin: 0 auto;
}

.priority-changer {
  padding: 66px 0 82px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 990px;
  height: 347px;
  background: var(--main-card-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__wrapper {
    width: 350px;
    margin: 0 auto;
  }
  img {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 12px;
  }

  .button__container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    button {
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      background: #2c698c;
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.333333px;
      color: #ffffff;
    }
  }
}
</style>
