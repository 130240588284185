<template>
  <div>
    <form class="wrapper" @submit.prevent="updateInfo" ref="form">
      <div class="observer">
        <img
          src="../../resource/img/modal/close2.svg"
          alt="close"
          @click="close"
          class="close"
        />
        <div class="observer__wrapper">
          <b-alert :show="visibleWindow" variant="warning"
            ><a href="#" class="alert-link">
              {{
                `${responsibleExecutor[0].firstName}  ${responsibleExecutor[0].lastName}   исполнитель в задаче и не может быть наблюдателем!`
              }}
            </a></b-alert
          >
          <div class="position-relative">
            Наблюдатель
            <span class="observer__select__img">
              <img
                class="observer__add"
                src="../../resource/img/plus_form.svg"
                alt="add"
                @click="addObserver"
              />
            </span>
          </div>
          <div v-for="(viewer, index) in viewers" :key="index">
            <div class="observer__select">
              <div class="position-relative">
                <b-input
                  v-if="!viewer.id"
                  style="marginTop:5px"
                  type="search"
                  v-model="employeeSearch"
                  @focus="
                    showEmployees = true;
                    employeeSearch = '';
                  "
                  @blur="hideEmployees"
                  placeholder="Введите наблюдателя"
                />
                <div v-else class="selected-observer">
                  {{ getViewerDisplayName(viewer) }}
                </div>
                <div
                  class="position-absolute optgroup employees"
                  v-if="showEmployees && index == focus"
                >
                  <div
                    v-for="item in sortedEmployeeByInput"
                    :key="item.id"
                    class="optgroup_item"
                    @click="selectEmployee(item)"
                  >
                    {{ item.value }}
                  </div>

                  <div
                    v-if="
                      !sortedEmployeeByInput.length && !isEmployeesListLoading
                    "
                  >
                    Не найдено
                  </div>
                  <div v-if="isEmployeesListLoading">Загрузка...</div>
                </div>
              </div>

              <div class="observer__select__img">
                <span>
                  <img
                    class="observer__remove"
                    src="../../resource/img/minus_form.svg"
                    alt="remove"
                    @click="removeObserver(viewer.id)"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="executor__wrapper__button__container">
            <button :disabled="isActionInProgress">
              <span v-if="!isActionInProgress">Сохранить</span>
              <b-spinner v-else small></b-spinner>
            </button>
            <button :disabled="isActionInProgress" @click="close">
              Отмена
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { user } from "../../utils/users";
import RequestManager from "@/function/request/RequestManager";
import { OrdersApi, CompanyApi } from "@/function/request/apiV2";

export default {
  name: "ChangeObserverModal",
  props: [
    "orderId",
    "currentEmployee",
    "responsibleViewers",
    "responsibleExecutor",
    "currentEmployeeExecutor",
    "currentExecutorId"
  ],
  emits: ["changeObserver"],
  data() {
    return {
      focus: 0,
      firstStart: true,
      employeeSearch: "",
      showEmployees: false,
      employee_id: null,
      visibleWindow: false,
      selectedObservers: [],
      employees: [],
      currentUser: undefined,
      mainCompanyInfo: undefined,
      isEmployeesListLoading: false,
      companyApi: new CompanyApi(),
      ordersApi: new OrdersApi(),
      observers: [],
      viewerIds: [],
      updatedViewers: [],
      viewers: [],
      allUsers: [],
      isActionInProgress: false
    };
  },
  methods: {
    change(event) {
      this.employeeSearch = event;
    },

    hideEmployees() {
      setTimeout(() => {
        this.showEmployees = false;
      }, 150);
    },

    selectEmployee(item) {
      this.employeeSearch = item.value;
      this.employee_id = item.id ?? this.crmManagerId;
      this.viewers[this.viewers.length - 1] = item;
      this.viewerIds[this.viewerIds.length] = item.id;
      this.employeeSearch = "";
    },

    checkedSearchEmployee() {
      if (!this.employeeSearch) {
        this.employee_id = undefined;
      }
      if (this.employee_id && !this.sortedEmployeeByInput.length) {
        this.employee_id = undefined;
      }
      if (
        this.sortedEmployeeByInput.length === 1 &&
        this.employeeSearch === this.sortedEmployeeByInput[0].value
      ) {
        this.employee_id = this.sortedEmployeeByInput[0].id;
      }
    },
    async getPermissions() {
      this.permissions = await this.ordersApi.api.getOrderPermissions(
        this.chatRouteId
      );
    },

    async edit() {
      await this.ordersApi.api.editOrderPermissions(
        this.orderId,
        this.viewersPermissions
      );
    },

    async removeObserver(id) {
      const foundIdx = this.viewerIds.indexOf(parseInt(id));
      if (foundIdx > -1) {
        this.viewerIds.splice(foundIdx, 1);
      }
      const foundViewerIdx = this.viewers.findIndex(viewer => viewer.id === id);
      if (foundViewerIdx > -1) {
        this.viewers.splice(foundViewerIdx, 1);
      }
    },

    addObserver() {
      // this.viewerIds.push(undefined);
      this.viewers.push({});
    },

    close() {
      this.$emit("close");
    },

    /* getFullName(employee) {
      return user.getFullName(employee);
    },*/

    getFullName(employee) {
      let {
        first_name,
        last_name,
        email,
        firstName,
        lastName,
        companyName,
        id
      } = employee;
      let result = email;
      first_name = first_name || firstName || "";
      last_name = last_name || lastName || "";

      if ((first_name || last_name) && companyName && companyName !== " ") {
        result = `${companyName}${" "}${"-"}${" "}${first_name} ${last_name}`;
      } else {
        result = ` ${result}`;
      }

      return { id: id, value: result };

      //  return user.getFullName(employee);
    },

    getViewerDisplayName(user) {
      if (user.value) {
        return user.value;
      }
      const foundEmployee = this.employees.find(
        empl => empl.id === parseInt(user.id)
      );
      if (foundEmployee) {
        return foundEmployee.value;
      } else {
        return `${user.firstName} ${user.lastName}`;
      }
    },

    async getDevices() {
      try {
        this.devices = [];
        this.selectedDeviceId = null;
        this.isLoading = true;
        const searchParams = {
          office: this.selectedOfficeId
        };
        const { results } = await RequestManager().getAllDevices(searchParams);
        this.devices = results;
      } finally {
        this.isLoading = false;
      }
    },

    async deleteExist() {
      await this.ordersApi.api.editOrderPermissions(
        this.orderId,
        this.deletedPermissions
      );
    },

    async updateInfo() {
      try {
        this.isActionInProgress = true;
        this.isLoading = true;

        // await this.deleteExist();
        await this.edit();
      } catch (e) {
        /* this.$bvToast.toast(e.error.message, {
          variant: "danger",
          title: "Ошибка"
        });
        throw new Error(e); */
      } finally {
        this.isLoading = false;
        await this.$emit("update");
        this.visibleWindow = false;
        this.close();
        this.viewers = [];
        this.isActionInProgress = false;
      }
    },

    async getRootCompanies() {
      const { items } = await this.companyApi.api.listCompanies({
        limit: 100000000,
        crmManagerId: this.currentUser.id
      });
      this.allCompanies = [...[this.mainCompanyInfo], ...items];
    },

    async getCompanyUsers() {
      try {
        this.isEmployeesListLoading = true;
        await this.getRootCompanies();
        const allCompaniesIds = this.allCompanies.map(comp =>
          parseInt(comp.id)
        );
        const { results } = await RequestManager().getUsersList();
        this.allUsers = results;
        this.employees = results.filter(
          res =>
            allCompaniesIds.includes(res.company ? res.company : -1) &&
            parseInt(res.id) !== this.currentExecutorId
        );
        this.isEmployeesListLoading = false;
      } catch (e) {
        throw new Error(e);
      } finally {
        // объединение- компании + пользователи в объекте
        this.employees = this.employees.map(item => {
          let company = this.allCompanies.find(
            company1 => +item.company == +company1?.id
          );
          if (company) {
            item.companyName = company.brand ? company.brand : "";
          }
          return item;
        });

        // в строчке - объект {id: id , value: value} , где value - компания + имя + отчество
        this.employees = this.employees.map(item => {
          return this.getFullName(item);
        });
      }

      // Сортировка
      if (this.employees && this.employees.length > 1)
        this.employees.sort((a, b) => a.value.localeCompare(b.value));

      if (this.crmManagerId !== this.currentExecutorId) {
        this.employees.unshift({
          id: this.crmManagerId,
          value: "Персональный менеджер"
        });
      }
    },

    /*  async getCompanyUsers() {
      try {
        const { results } = await RequestManager().getUsersList();
        this.employees = results;
      } catch (e) {
        throw new Error(e);
      }
    },*/

    async getStatuses() {
      try {
        this.statuses = await this.ordersApi.api.getOrderStatuses();
      } catch (e) {
        throw new Error(e);
      }
    },

    async getTypes() {
      try {
        this.types = await this.ordersApi.api.getOrderTypes();
        this.selectedType = this.types[0];
      } catch (e) {
        throw new Error(e);
      }
    },

    viewersName(index) {
      let search = this.employees.find(
        item => item.id == this.viewerIds[index]
      );
      if (search) return search.value;
      else return "";
    }
  },

  async mounted() {
    const permissions = await this.ordersApi.api.getOrderPermissions(
      this.chatRouteId
    );

    this.viewers = permissions.filter(item => {
      return item.roles.includes("viewer");
    });
    if (this.viewers.length) {
      this.viewers = this.viewers.map(viewer => viewer.user);
    } else {
      this.viewers = [{}];
    }

    if (this.viewers.length === 0) {
      this.viewerIds = [undefined];
    } else {
      this.viewerIds = this.viewers.map(item => +item.id);
    }
  },

  computed: {
    sortedEmployeeByInput() {
      let newEmployees = this.employees.filter(el => {
        return el.value
          .toLowerCase()
          .includes(this.employeeSearch.toLowerCase());
      });
      // .sort((a, b) => a.value.localeCompare(b.value));

      return newEmployees;
    },

    viewersPermissions() {
      let ids = [];
      if (!this.viewerIds[0]) {
        ids = this.viewerIds.slice(1);
      } else {
        ids = this.viewerIds;
      }
      const newData = ids.map(el => {
        return {
          userId: el.toString(),
          roles: ["viewer"]
        };
      });
      this.responsibleViewers.forEach(viewer => {
        const foundViewer = newData.find(data => data.userId === viewer.id);
        if (!foundViewer) {
          newData.push({ userId: viewer.id, roles: [] });
        }
      });
      return newData;
    },

    deletedPermissions() {
      return this.responsibleViewers?.map(el => {
        return {
          userId: el.id ? "" + el.id : "" + this.crmManagerId,
          roles: []
        };
      });
    },

    nonObservingEmployees() {
      return this.employees.filter(item => {
        return !this.viewerIds.includes(!item.id);
      });
    },

    companyId() {
      return this.$store.getters.currentUser?.company;
    },

    chatRouteId() {
      return this.$route.params.id;
    },

    crmManagerId() {
      return this.$store.getters?.mainCompanyInfo?.crm_manager;
    },

    offices() {
      return this.$store.getters?.officeList;
    },

    references() {
      return {
        devicesIds: this.selectedDeviceId
          ? [this.selectedDeviceId?.toString()]
          : undefined
      };
    },

    orderCreateForm() {
      return {
        companyId: "" + this.companyId,
        title: this.title ?? this.selectedType.id,
        typeId: this.selectedType.id,
        statusId: "2",
        budget: this.budget ?? undefined,
        deadline: this.deadline ?? undefined,
        texts: this.texts
      };
    }
  },

  async created() {
    this.currentUser = JSON.parse(window.localStorage.getItem("currentUser"));
    this.mainCompanyInfo = JSON.parse(
      window.localStorage.getItem("mainCompanyInfo")
    );
    await this.getCompanyUsers();
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.observer {
  padding: 66px 0 82px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  background: var(--main-card-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__select {
    position: relative;
    &__img {
      position: absolute;
      top: 50%;
      right: -50px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  &__wrapper {
    width: 350px;
    margin: 0 auto;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 12px;
  }
  &__input {
    width: 350px;
    height: 46px;
    background: #f1f1f3;
    border-radius: 4px;
  }
  .executor__wrapper__button__container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    button {
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      background: #2c698c;
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.333333px;
      color: #ffffff;
    }
  }
}

.choose {
  margin-bottom: 15px;
}

.employees {
  background-color: var(--main-card-color);
  .optgroup_item:hover {
    background-color: rgba(184, 218, 255, 0.5);
  }
}

.optgroup {
  padding: 5px 10px;
  position: absolute;
  width: 100%;
  max-height: 220px;
  border: 1px solid #ced4da;
  z-index: 9;
  background-color: white;
  overflow-y: auto;
  overflow-x: auto;

  &_item {
    cursor: pointer;

    &:hover {
      background-color: #fafafc;
    }
  }
}

.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.executor {
  padding: 66px 0 82px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 990px;
  height: 347px;
  background: var(--main-card-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__wrapper {
    width: 350px;
    margin: 0 auto;
  }
  img {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 12px;
  }
  &__input {
    width: 350px;
    height: 46px;
    background: var(--input-executor-bgcolor);
    color: var(--input-executor-color);
    border-radius: 4px;
  }

  .executor__wrapper__button__container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    button {
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      background: #2c698c;
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.333333px;
      color: #ffffff;
    }
  }
}

.close-img {
  filter: var(--filter-for-svg);
  cursor: pointer;
}

.observer__select .selected-observer {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
