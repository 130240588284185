<!-- Изменение статуса задачи -->

<template>
  <section class="wrapper">
    <div class="status-block">
      <div class="d-flex" style="justify-content: space-between">
        <h5 class="mb-3 ml-1">Изменение статуса задачи</h5>
        <div class="c-pointer font-weight-bold" @click="close">x</div>
      </div>

      <div class="status-btn-wrap my-3">
        <div
          class="d-flex align-items-center mt-2"
          v-if="!addStage"
          :title="
            !statusList.length
              ? 'У наблюдателя нет права менять статус задачи'
              : 'Смена статуса задачи'
          "
        >
          <button
            @click="addStage = true"
            class="status-btn status-btn--plus"
            :disabled="!statusList.length"
          >
            +
          </button>
          <button
            @click="addStage = true"
            class="status-btn"
            :disabled="!statusList.length"
          >
            Добавить этап
          </button>
        </div>

        <div v-else class="addstage">
          <button @click="addStage = false" class="addstage-btn">x</button>
          <span class="add-stage-text">
            Добавить этап
          </span>
          <span>Статус : </span>
          <b-form-select class="vue-select" v-model="status">
            <option
              v-for="status in statusList"
              :key="status.id"
              :value="status.id"
            >
              {{ status.title }}
            </option>
          </b-form-select>
          <div
            v-if="!isChangeOrderStatusInProgress"
            class="add-stage-btn"
            @click="editOrderStatus"
          >
            <img :src="checkIcon" alt="" />
          </div>
          <div v-else>
            <b-spinner small />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import vuescroll from "vuescroll";
import { OrdersApi } from "@/function/request/apiV2";
import { mapGetters } from "vuex";

export default {
  name: "Chat",
  components: {
    //vuescroll
    // Select2
  },
  props: {
    statuses: {
      type: Array
    },
    history: {
      type: Array
    },
    order: {
      type: Object
    },

    value: File,
    topic: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },

  data() {
    return {
      date: new Date(),
      time: new Date(),
      ordersApi: new OrdersApi(),
      checkIcon: require("../../resource/img/check.svg"),
      status: "1",
      statusList: [],
      addStage: false,
      timezone: "",
      ordersCount: 0,
      orderClicked: false,
      interval: null,
      fileIcon: require("../../resource/img/file.svg"),
      fileOwnIcon: require("../../resource/img/own-file.svg"),
      iconClock: require("../../resource/img/clock.svg"),
      managerActive: false,
      chatScrollOptions: {},
      mainCompanyId: null,
      file: null,
      fileDescription: "",
      statusArray: [],
      chatMessages: [],
      chatId: 0,
      message: "",
      messagesCount: 0,
      active: true,
      activeOrder: 0,
      orders: [],
      activeStatusIndex: 2,
      ownerId: undefined,
      performancesIds: undefined,
      viewersIds: undefined,
      isChangeOrderStatusInProgress: false
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),

    _orders() {
      return this.orders.filter(el => el.current_status?.id !== 4);
    },

    _activeOrder() {
      const chatId = parseInt(this.$route.params.id);
      const [order] = this.orders?.filter(el => el.id === chatId);
      return order;
    },

    page() {
      return this.$route.name;
    },

    getStageTime() {
      const hours = this.time.getHours();
      const minutes =
        this.time.getMinutes().toString().length === 1
          ? "0" + this.time.getMinutes()
          : this.time.getMinutes();
      return hours + " : " + minutes;
    },

    chatRouteId() {
      return this.$route.params.id;
    }
  },

  methods: {
    getTime(date) {
      const time = new Date(date);
      const hours = time.getHours();
      const minutes =
        time.getMinutes().toString().length === 1
          ? "0" + time.getMinutes()
          : time.getMinutes();

      return hours + ":" + minutes;
    },

    close() {
      this.$emit("close");
    },

    /*
  "title": "string",    ***
    "texts": {
      "additionalProp1": "string",
      "additionalProp2": "string",
      "additionalProp3": "string"
    },
    "filePaths": [],
    "typeId": "string",   ***
    "statusId": "string",   ****
    "tagIds": [     ***
      "string"      ***
    ],              ***
    "budget": 0,
    "estimatedTime": 0,
    "deadline": "2023-03-29T11:24:03.287Z"
  */
    async editOrderStatus() {
      try {
        if (this.isChangeOrderStatusInProgress) {
          return;
        }

        let data = {
          texts: this.order.texts,
          title: this.order.title,
          statusId: this.status,
          typeId: this.order.typeId,
          tagIds: this.order.tagIds,
          budget: this.order.budget,
          filePaths: this.order.filePaths,
          estimatedTime: this.order.estimatedTime,
          deadline: this.order.deadline,
          priority: this.order.priority
        };

        this.isChangeOrderStatusInProgress = true;

        await this.ordersApi.api.editOrder(this.order.id, data);
        this.ordersApi.api.getOrderStatuses();
      } catch (e) {
        throw Error(e);
      } finally {
        this.isChangeOrderStatusInProgress = false;
        this.$emit("update");
        this.close();
      }
    }
  },
  created() {
    const currentUserId = JSON.parse(window.localStorage.getItem("currentUser"))
      .id;

    const owner = this.order.permissions.filter(item => {
      return item.roles.includes("owner");
    });
    const foundViewers = this.order.permissions.filter(item =>
      item.roles.includes("viewer")
    );
    const foundPerformers = this.order.permissions.filter(item =>
      item.roles.includes("performer")
    );

    this.ownerId = parseInt(owner[0]?.userId);
    this.performancesIds = foundPerformers.map(perf => parseInt(perf.userId));
    this.viewersIds = foundViewers.map(viewer => parseInt(viewer.userId));

    /* console.log("ownerId", this.ownerId);
      console.log("performancesIds", this.performancesIds);
      console.log("viewersIds", this.viewersIds);
  
      console.log("statuses", this.statuses); */

    if (this.viewersIds.includes(currentUserId)) {
      this.statusList = [];
    }
    if (this.performancesIds.includes(currentUserId)) {
      this.statusList = this.statuses.filter(
        status => status.title !== "Закрыт"
      );
    }
    if (currentUserId === this.ownerId) {
      this.statusList = this.statuses;
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.attachment {
  padding: 66px 65px 82px 65px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 453px;
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  &__header {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -0.333333px;
    color: #393952;
  }
  &__wrapper {
    margin-top: 35px;
    &__info {
      width: 350px;
      height: 46px;
      background: #e5f1f5;
      border-radius: 10px;
      margin-top: 21px;
      padding-left: 20px;
      display: flex;
      justify-content: left;
      align-items: center;
      position: relative;
      cursor: pointer;
      :first-child {
        margin-top: 0;
      }
      span {
        font-family: "Raleway";
        position: absolute;
        left: 80px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.333333px;
        color: #393952;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}
.vue-select {
  width: 150px;
  margin: 0 5px;
}
.select2-dropdown--above {
  display: flex;
  flex-direction: column;
}
.select2-dropdown--above .select2-search--dropdown {
  order: 2;
}
.select2-dropdown--above .select2-results {
  order: 1;
}

.select2-dropdown--below {
  display: flex;
  flex-direction: column;
}
.select2-dropdown--below .select2-search--dropdown {
  order: 1;
}
.select2-dropdown--below .select2-results {
  order: 2;
}
.select2-container .select2-selection--single {
  height: 20px !important;
}
.add-stage-text {
  color: #51a2be;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}
.add-stage-btn {
  width: 20px;
  padding: 0;
  height: 20px;
  border: 2px solid #51a2be;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  img {
    position: relative;
    top: -5px;
    width: 100%;
    height: 100%;
  }
}
.addstage {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
}
.time-btn {
  width: 130px;
  margin-right: 3px;
  outline: none !important;
  border: none;
}
.date-btn {
  width: 120px;
}
.request__need-wrap {
  position: relative;
  margin-bottom: 27px;
  padding-top: 5px;
  padding-bottom: 5px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: #e5f1f5;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  }

  &--chat {
    margin: 0;
  }
}
.request__need-title {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  /* identical to box height */
  letter-spacing: 0.04em;

  color: #ffffff;

  padding-left: 20px;

  &--dark {
    color: #4b4b67;
  }
}
.request__need-status {
  padding-left: 20px;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #b9dae5;

  &--color {
    color: #b9dae5;
    font-weight: 600;
  }

  &--dark {
    color: #4b4b67;
  }

  &--color-blue {
    color: #1c74fc;
    font-weight: 600;
  }
}
.request-table {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #b9dae5;
}

.request-table td {
  padding-bottom: 10px;
}

.request-table__title {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  padding-right: 20px;
}
.status-btn-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.status-btn {
  padding: 4px 12px;
  outline: none;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  border-radius: 6px;
  background-color: transparent;

  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #cfcfcf;

  &:not(:last-child) {
    margin-right: 7px;
  }

  &--plus {
    position: relative;
    padding: 0;
    width: 20px;
    height: 20px;
    font-size: 0;

    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;

      width: 11px;
      height: 11px;

      background-image: url("../../resource/img/grey-plus.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }
}
.status-btn.active {
  background-image: none;
  border: red;
}
.serial_number {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  color: black;
  font-weight: bold;
}

.__vuescroll {
  height: 100% !important;
}

.modal-button {
  position: relative;
  padding: 10px;
  width: 150px;
  border: 1px solid #006b90;
  letter-spacing: 0.04em;
  color: #006b90;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border-radius: 0.2em;
  outline: none;
  cursor: pointer;
  .file {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0 !important;
    width: 150px;
    height: 100%;
    margin: 0;
    opacity: 0;
  }
}

.scroll-container-orders {
  width: 350px;
  overflow: hidden;
  height: calc(100vh - 78px);
  .__vuescroll {
    width: 350px;
  }
  .scroll-container-messages {
    height: 100%;
  }
  .scroll-container-history {
    min-height: 120px;
    max-height: 65%;
  }
  .status-item {
    display: flex;
    align-items: flex-end;
    .status-item__title {
      position: relative;
      top: 5px;
      display: flex;
      align-items: center;
    }
    .item-title {
      display: flex;
      align-items: center;
      margin: 0 5px 0 10px;
      border-radius: 10px;
      color: #006b90;
      font-weight: 600;
      font-size: 14px;
      padding: 5px 10px;
      background: #e6f1f4;
    }
    .item-title.not-checked-title {
      color: #fcbc03;
      background: #f2eae7;
    }
    &__checked-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    &__checked {
      width: 20px;
      height: 20px;
      background: #51a2be;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .checkline {
        position: relative;
        top: -2px;
        width: 30%;
        height: 50%;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        transform: rotate(45deg);
      }
    }
    .not-checked {
      background: white;
      border-radius: 50%;
      border: 2px solid #fcbc03;
      .checkline {
        position: relative;
        top: -2px;
        width: 30%;
        height: 50%;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        transform: rotate(45deg);
      }
    }
    &__line {
      width: 4px;
      height: 20px;
      background: #51a2be;
    }
  }
  .status-block {
    background: var(--main-card-color);
    border-radius: 6px;
    padding: 20px;
    width: 500px;
    margin: auto auto;
    transform: translateY(50%);
  }

  .addstage-btn {
    border: 2px solid #51a2be;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 0;
    padding: 0;
    // font-size: 20px;
    color: #51a2be;
    font-weight: 700;
    margin-right: 10px;
    outline: none;
  }
  .active {
    background: #51a2be !important;
    color: white;
    span {
      color: white !important;
      font-size: 12px;
    }
  }
  .my-request__item.manager {
    margin-bottom: 0;
    .manager-image {
      width: 60px;
    }
    .my-request__title {
      margin-bottom: 0;
      margin-left: 30px;
      width: 80%;
      font-size: 16px;
      line-height: 27px;
      text-align: start;
    }
  }
  .my-request__item {
    padding: 10px 0 10px 20px;
    display: flex;
    min-height: 90px;
    max-width: 328px;
    // max-height: 80px;
    background: #ffffff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
    border-radius: 6px;
    //padding-left: 20px;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    h3 {
      margin-top: 0;
      font-size: 18px;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
      span {
        font-weight: 700;
      }
      .yellow {
        color: #fbbd3b;
      }
      .blue {
        color: #2378f9;
      }
    }
    .divider {
      width: 0;
      height: 80px;
      background: #e5f1f5;
      border: 3px solid #e5f1f5;
      border-radius: 5px;
      margin: 0 20px 0 10px;
    }

    // &:not(:last-child) {
    //   margin-right: 13px;
    // }
  }
  .my-request__item.title {
    max-width: 100%;
  }
  .chat-wrap {
    position: relative;
    top: 5px;
    display: flex;
    align-items: center;
  }
  .item-title {
    display: flex;
    align-items: center;
    margin: 0 5px 0 10px;
    border-radius: 10px;
    color: #006b90;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    background: #e6f1f4;
  }
  .item-title.not-checked-title {
    color: #fcbc03;
    background: #f2eae7;
  }
  &__checked-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &__checked {
    width: 20px;
    height: 20px;
    background: #51a2be;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .checkline {
      position: relative;
      top: -2px;
      width: 30%;
      height: 50%;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }
  }
  .not-checked {
    background: white;
    border-radius: 50%;
    border: 2px solid #fcbc03;
    .checkline {
      position: relative;
      top: -2px;
      width: 30%;
      height: 50%;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }
  }
  &__line {
    width: 4px;
    height: 20px;
    background: #51a2be;
  }
}
.status-block {
  background: var(--main-card-color);
  border-radius: 6px;
  padding: 20px;
  width: 900px;
  margin: auto auto;
  transform: translateY(50%);
}

.addstage-btn {
  border: 2px solid #51a2be;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 0;
  padding: 0;
  // font-size: 20px;
  color: #51a2be;
  font-weight: 700;
  margin-right: 10px;
  outline: none;
}
.active {
  background: #51a2be !important;
  color: white;
  span {
    color: white !important;
    font-size: 12px;
  }
}
.my-request__item.manager {
  margin-bottom: 0;
  .manager-image {
    width: 60px;
  }
  .my-request__title {
    margin-bottom: 0;
    margin-left: 30px;
    width: 80%;
    font-size: 16px;
    line-height: 27px;
    text-align: start;
  }
}
.my-request__item {
  padding: 10px 0 10px 20px;
  display: flex;
  min-height: 90px;
  max-width: 328px;
  // max-height: 80px;
  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  //padding-left: 20px;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  h3 {
    margin-top: 0;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
    span {
      font-weight: 700;
    }
    .yellow {
      color: #fbbd3b;
    }
    .blue {
      color: #2378f9;
    }
  }
  .divider {
    width: 0;
    height: 80px;
    background: #e5f1f5;
    border: 3px solid #e5f1f5;
    border-radius: 5px;
    margin: 0 20px 0 10px;
  }

  // &:not(:last-child) {
  //   margin-right: 13px;
  // }
}
.my-request__item.title {
  max-width: 100%;
}
.chat-wrap {
  position: relative;
  display: flex;
  width: 100%;
  //height: 100vh;
  height: calc(100vh - 78px);
  bottom: 10px;
}

.chat {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 10px;
  border: 3px solid #ffffff;
  border-bottom: 0;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  border-radius: 6px;
  padding: 0;
  height: 100%;
}

.chat__title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;

  padding: 23px 27px 24px 32px;

  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  height: 90px;
}

.chat__list {
  display: flex;
  flex-direction: column-reverse;
  //align-items: flex-end;
  overflow-y: auto;
  margin-bottom: 22px;
  padding: 22px 24px;
  padding-bottom: 0;

  flex: 1 0 auto;
  //max-height: 65%;
  max-height: calc(65% - 152px);
}
.chat__list.manager {
  max-height: 75%;
}

.chat__bottom-panel {
  //position: relative;
  position: sticky;
  top: calc(100vh - 75px);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  bottom: 0;
  flex: 0 0 auto;

  padding: 17px 27px;
  background: #ffffff;
  border-radius: 0 0 25px 25px;
  transform: rotate(-180deg);
}

.chat__name {
  display: flex;
  flex-direction: column;
}

.chat__id {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;

  color: #006b90;
}

.chat__you-msg,
.chat__other-msg {
  padding: 14px 16px 14px 19px;
  border-radius: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  margin-bottom: 12px;
  .file-icon {
    cursor: pointer;
    margin-right: 10px;
  }
}

.check-new-date {
  opacity: 0.5;
}
.chat__you-msg {
  .date-label {
    display: flex;
    justify-content: flex-end;
  }
}
.chat__other-msg {
  .date-label {
    display: flex;
    justify-content: flex-end;
  }
}
.date-label {
  font-size: 10px;
  opacity: 0.5;
}

.timeline-wrapper {
  max-height: 360px;
  overflow-y: auto;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid var(--help-popover-color);
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
</style>
