<template>
  <form class="wrapper" @submit.prevent="updateInfo" ref="form">
    <div class="executor">
      <img
        src="../../resource/img/modal/close2.svg"
        alt="close"
        @click="close"
        class="close-img"
      />
      <div class="executor__wrapper">
        <div>Исполнитель:</div>
        <!--
        <b-select v-model="selectedEmployee" class="executor__input">
          <option :value="undefined">Персональный менеджер</option>
          <option
            v-for="item in nonObservingEmployees"
            :key="item.id"
            :value="item.id"
          >
            {{ getFullName(item) }}
          </option>
        </b-select> -->
        <!-- @input="checkedSearchEmployee" -->

        <div class="position-relative">
          <b-input
            style="marginTop:5px"
            type="text"
            v-model="employeeSearch"
            @focus="
              showEmployees = true;
              employeeSearch = '';
            "
            @blur="hideEmployees"
            placeholder="Введите исполнителя"
          />
          <div
            class="position-absolute optgroup employees"
            v-if="showEmployees"
          >
            <div
              v-for="item in sortedEmployeeByInput"
              :key="item.id"
              class="optgroup_item"
              @click.prevent="selectEmployee(item)"
            >
              {{ item.value }}
            </div>

            <div
              v-if="!sortedEmployeeByInput.length && !isEmployeesListLoading"
            >
              Не найдено
            </div>
            <div v-if="isEmployeesListLoading">Загрузка...</div>

            <!--  <span v-if="!sortedEmployeeByInput.length">Не найдено</span> -->
          </div>
        </div>

        <div class="executor__wrapper__button__container">
          <button>Сохранить</button>
          <button @click="close">Отмена</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// import { user } from "../../utils/users";
import RequestManager from "@/function/request/RequestManager";
import { OrdersApi, CompanyApi } from "@/function/request/apiV2";

export default {
  name: "ChangeExecutorModal",
  props: ["orderId", "currentEmployee", "responsible"],
  data() {
    return {
      firstStart: true,
      employeeSearch: "",
      employees: [],
      showEmployees: false,
      employee_id: undefined,
      currentUser: undefined,
      mainCompanyInfo: undefined,
      isEmployeesListLoading: false,

      //  selectedEmployee: undefined,

      companyApi: new CompanyApi(),
      ordersApi: new OrdersApi(),
      viewerIds: []
    };
  },
  methods: {
    hideEmployees() {
      setTimeout(() => {
        this.showEmployees = false;
      }, 150);
    },

    selectEmployee(item) {
      this.employeeSearch = item.value;
      this.employee_id = item.id ?? this.crmManagerId;
      // console.log(" this.employeeSearch", this.employeeSearch);
      // console.log(" this.employee_id", this.employee_id);
    },

    checkedSearchEmployee() {
      if (!this.employeeSearch) {
        this.employee_id = undefined;
      }
      if (this.employee_id && !this.sortedEmployeeByInput.length) {
        this.employee_id = undefined;
      }
      if (
        this.sortedEmployeeByInput.length === 1 &&
        this.employeeSearch === this.sortedEmployeeByInput[0].value
      ) {
        this.employee_id = this.sortedEmployeeByInput[0].id;
      }
    },

    getFullName(employee) {
      let {
        first_name,
        last_name,
        email,
        firstName,
        lastName,
        companyName,
        id
      } = employee;
      let result = email;
      first_name = first_name || firstName || "";
      last_name = last_name || lastName || "";

      if ((first_name || last_name) && companyName && companyName !== " ") {
        result = `${companyName}${" "}${"-"}${" "}${first_name} ${last_name}`;
      } else {
        result = ` ${result}`;
      }

      return { id: id, value: result };

      //  return user.getFullName(employee);
    },

    async getRootCompanies() {
      const { items } = await this.companyApi.api.listCompanies({
        limit: 100000000,
        crmManagerId: this.currentUser.id
      });
      this.allCompanies = [...[this.mainCompanyInfo], ...items];
    },

    async getCompanyUsers() {
      try {
        this.isEmployeesListLoading = true;
        await this.getRootCompanies();
        const allCompaniesIds = this.allCompanies.map(comp =>
          parseInt(comp.id)
        );
        const { results } = await RequestManager().getUsersList();
        this.employees = results.filter(res =>
          allCompaniesIds.includes(res.company ? res.company : -1)
        );
        this.isEmployeesListLoading = false;
      } catch (e) {
        throw new Error(e);
      } finally {
        // объединение- компании + пользователи в объекте
        this.employees = this.employees.map(item => {
          // console.log("item",item);
          let company = this.allCompanies.find(
            company1 => +item.company == +company1?.id
          );
          if (company) {
            item.companyName = company.brand ? company.brand : "";
          }
          return item;
        });

        // в строчке - объект {id: id , value: value} , где value - компания + имя + отчество
        this.employees = this.employees.map(item => {
          return this.getFullName(item);
        });
      }

      // Сортировка
      if (this.employees && this.employees.length > 1)
        this.employees.sort((a, b) => a.value.localeCompare(b.value));

      this.employees.unshift({ id: undefined, value: "Персональный менеджер" });
    },

    async editPermissions() {
      await this.ordersApi.api.editOrderPermissions(
        this.orderId,
        this.permissions
      );
    },

    close() {
      this.$emit("close");
    },

    /* getFullName(employee) {
      return user.getFullName(employee);
    },*/

    async getDevices() {
      try {
        this.devices = [];
        this.selectedDeviceId = null;
        this.isLoading = true;
        const searchParams = {
          office: this.selectedOfficeId
        };
        const { results } = await RequestManager().getAllDevices(searchParams);
        this.devices = results;
      } finally {
        this.isLoading = false;
      }
    },

    async updateInfo() {
      try {
        this.isLoading = true;
        await this.editPermissions();
      } catch (e) {
        this.$bvToast.toast(e.error.message, {
          variant: "danger",
          title: "Ошибка"
        });
        throw new Error(e);
      } finally {
        this.isLoading = false;
        this.$emit("update", this.employee_id);
        this.close();
      }
    },

    /* async getCompanyUsers() {
      try {
        const { results } = await RequestManager().getUsersList();
        this.employees = results;
      } catch (e) {
        throw new Error(e);
      }
    },*/

    async getStatuses() {
      try {
        this.statuses = await this.ordersApi.api.getOrderStatuses();
      } catch (e) {
        throw new Error(e);
      }
    },

    async getTypes() {
      try {
        this.types = await this.ordersApi.api.getOrderTypes();
        this.selectedType = this.types[0];
      } catch (e) {
        throw new Error(e);
      }
    }
  },
  computed: {
    sortedEmployeeByInput() {
      // console.log("employees", this.employees);

      let newEmployees = this.employees.filter(el => {
        //  console.log("el", el);
        return el.value
          .toLowerCase()
          .includes(this.employeeSearch.toLowerCase());
      });
      // .sort((a, b) => a.value.localeCompare(b.value));

      // console.log("newEmployees", newEmployees);

      return newEmployees;
    },

    permissions() {
      const currentResponsible = {
        userId: this.responsible?.[0]?.id,
        roles: []
      };

      const newResponsible = {
        //    userId: "" + (this.selectedEmployee ?? this.crmManagerId),
        userId: "" + (this.employee_id ?? this.crmManagerId),
        roles: ["performer"]
      };

      return this.responsible?.length
        ? [currentResponsible, newResponsible]
        : [newResponsible];
    },

    nonObservingEmployees() {
      return this.employees.filter(item => {
        return !this.viewerIds.includes(!item.id);
      });
    },

    companyId() {
      return this.$store.getters.currentUser?.company;
    },

    chatRouteId() {
      return this.$route.params.id;
    },

    crmManagerId() {
      return this.$store.getters?.mainCompanyInfo?.crm_manager;
    },

    offices() {
      return this.$store.getters?.officeList;
    },

    references() {
      return {
        devicesIds: this.selectedDeviceId
          ? [this.selectedDeviceId?.toString()]
          : undefined
      };
    },

    orderCreateForm() {
      return {
        companyId: "" + this.companyId,
        title: this.title ?? this.selectedType.id,
        typeId: this.selectedType.id,
        statusId: "2",
        budget: this.budget ?? undefined,
        deadline: this.deadline ?? undefined,
        texts: this.texts
      };
    }
  },
  watch: {
    //  selectedEmployee() {}
    employeeSearch() {}
  },
  async created() {
    this.currentUser = JSON.parse(window.localStorage.getItem("currentUser"));
    this.mainCompanyInfo = JSON.parse(
      window.localStorage.getItem("mainCompanyInfo")
    );
    if (this.responsible.length) {
      this.selectedEmployee = this.responsible[0].id;
      this.employeeSearch = ""; // this.responsible[0].firstName + " " + this.responsible[0].lastName;
      this.employee_id = this.responsible[0].id;
    }
    // this.getStatuses();
    // this.getTypes();
    this.getCompanyUsers();
  }
};
</script>

<style lang="scss" scoped>
.employees {
  background-color: var(--main-card-color);
  .optgroup_item:hover {
    background-color: rgba(184, 218, 255, 0.5);
  }
}

.optgroup {
  padding: 5px 10px;
  position: absolute;
  width: 100%;
  max-height: 220px;
  border: 1px solid #ced4da;
  z-index: 9;
  background-color: white;
  overflow-y: auto;
  overflow-x: auto;

  &_item {
    cursor: pointer;

    &:hover {
      background-color: #fafafc;
    }
  }
}

.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.executor {
  padding: 66px 0 82px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 990px;
  height: 347px;
  background: var(--main-card-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__wrapper {
    width: 350px;
    margin: 0 auto;
  }
  img {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 12px;
  }
  &__input {
    width: 350px;
    height: 46px;
    background: var(--input-executor-bgcolor);
    color: var(--input-executor-color);
    border-radius: 4px;
  }

  .executor__wrapper__button__container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    button {
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      background: #2c698c;
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.333333px;
      color: #ffffff;
    }
  }
}

.close-img {
  filter: var(--filter-for-svg);
  cursor: pointer;
}
</style>
