const user = {
  getFullName: user => {
    let { first_name, last_name, email, firstName, lastName } = user;
    let result = email;
    first_name = first_name || firstName || "";
    last_name = last_name || lastName || "";

    if (first_name && last_name) {
      result = `${first_name} ${last_name}`;
    }

    return result;
  }
};

export { user };
