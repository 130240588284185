<template>
  <section class="wrapper">
    <div class="attachment-type">
      <img
        @click="close"
        src="../../resource/img/modal/close.svg"
        alt="close"
        class="attachment__close"
      />
      <img
        src="../../resource/img/modal/back.svg"
        alt="back"
        class="attachment__back"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "PhotoAttachmentModal",
  emits: ["close"],
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.attachment-type {
  padding: 66px 65px 82px 65px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 453px;
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  &__back {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
</style>
