<template>
  <section class="wrapper">
    <div class="attachment">
      <h1 class="attachment__header">Вложения</h1>
      <img
        @click="close"
        src="../../resource/img/modal/close.svg"
        alt="close"
        class="attachment__close"
      />
      <div class="attachment__wrapper">
        <div
          class="attachment__wrapper__info"
          @click="showPhoto"
          @close="close"
        >
          <img src="../../resource/img/modal/photo.svg" alt="photo" />
          <span>{{ photo }} фото</span>
        </div>
        <div class="attachment__wrapper__info">
          <img src="../../resource/img/modal/video.svg" alt="video" />
          <span>{{ video }} видео</span>
        </div>
        <div class="attachment__wrapper__info">
          <img src="../../resource/img/modal/files.svg" alt="files" />
          <span>{{ files }} файлов</span>
        </div>
        <div class="attachment__wrapper__info">
          <img src="../../resource/img/modal/links.png" alt="links" />
          <span>{{ links }} ссылок</span>
        </div>
      </div>
      <PhotoAttachmentModal v-if="isShowPhoto" @close="close" />
    </div>
  </section>
</template>

<script>
import PhotoAttachmentModal from "./PhotoAttachmentModal";

export default {
  name: "AttachmentModal",
  components: { PhotoAttachmentModal },
  data() {
    return {
      photo: 0,
      video: 0,
      files: 0,
      links: 0,
      isShowPhoto: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },

    closePhoto() {
      this.isShowPhoto = false;
    },

    showPhoto() {
      this.isShowPhoto = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.attachment {
  padding: 66px 65px 82px 65px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 453px;
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  &__header {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -0.333333px;
    color: #393952;
  }
  &__wrapper {
    margin-top: 35px;
    &__info {
      width: 350px;
      height: 46px;
      background: #e5f1f5;
      border-radius: 10px;
      margin-top: 21px;
      padding-left: 20px;
      display: flex;
      justify-content: left;
      align-items: center;
      position: relative;
      cursor: pointer;
      :first-child {
        margin-top: 0;
      }
      span {
        font-family: "Raleway";
        position: absolute;
        left: 80px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.333333px;
        color: #393952;
      }
    }
  }
}
</style>
