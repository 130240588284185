<template>
  <tepmplate-block mod-class content-class="d-block " title-page="Задача">
    <div class="order order-wrapper">
      <div class="order-search" v-if="currentOrder">
        <div class="order-search__content" v-if="isShowSearchBar">
          <div class="order-search__bar">
            <b-input
              type="search"
              v-model="searchOrder"
              @keyup.enter="getOrdersByDeviceId()"
              @change="getOrdersByDeviceId()"
              debounce="500"
              placeholder="Название, серийный/стикер номер"
            />
          </div>
          <div class="scroller">
            <div
              class="d-flex justify-content-center align-items-center align-self-center scroll-container"
              v-if="ordersListIsLoading"
            >
              <b-spinner />
            </div>

            <vuescroll v-else>
              <div class="orders-list-wrapper">
                <request-list-item
                  :is-active="true"
                  :request="currentOrder"
                  class="slim-order-item"
                />
                <request-list-item
                  v-for="item in items"
                  :key="item.id"
                  :request="item"
                  class="slim-order-item"
                />
              </div>
            </vuescroll>
          </div>
        </div>
        <div
          class="order-search__toggle"
          @click="isShowSearchBar = !isShowSearchBar"
        >
          <span class="icon" :class="isShowSearchBar ? 'min' : 'max'" />
        </div>
      </div>

      <div class="order-content" v-if="currentOrder">
        <div
          class="order-content__info info"
          :class="{ 'scroll-y': isShowDetailInfo }"
        >
          <div
            id="title"
            v-b-tooltip.focus
            :title="titleLong(currentOrder)"
            style="textAlign:left; backgroundColor: var(--order-title-block-color); height:60px; display:flex; flexWrap:wrap; alignItems: center;
            paddingLeft:10px; paddingRight: 80px"
          >
            <span class="font-weight-bold data-order-title">
              ({{ currentOrder.id }}) {{ nameTaskLong(currentOrder.title) }}
            </span>
          </div>
          <b-popover
            v-if="
              currentOrder.title &&
                nameTaskLong(currentOrder.title).length <
                  currentOrder.title.length
            "
            custom-class="p-2"
            target="title"
            placement="bottom"
            triggers="hover focus"
          >
            <div>{{ currentOrder.title }}</div>
          </b-popover>
          <div class="info-items" v-if="isShowDetailInfo">
            <div class="info-items__description">
              <div class="d-flex">
                <span class="font-weight-bold mr-2 data-status-label">
                  Текущий статус:
                </span>
                <span
                  :class="getStatusColor"
                  class="font-weight-bold data-status-text"
                  @click="showStatusModal"
                  style="cursor:pointer"
                >
                  {{ currentOrder.status.title }}
                  <img src="../../assets/edit.svg" class="img-edit" alt="" />
                  <help-popover
                    v-if="helpPopovers.currentStatus"
                    :message="helpPopovers.currentStatus"
                    class="ml-2 help-icon"
                    :is-inline-block="true"
                  />
                </span>
              </div>

              <div v-b-tooltip.focus>
                <span class="font-weight-bold data-short-description-label">
                  Краткое описание проблемы:
                </span>
                <span
                  class="fake-link"
                  title="Копировать содержимое поля"
                  @click="copyText('shortdescription')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-files"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2
                    2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2
                    2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1
                    1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0
                    0 1-1 1H4a1 1 0 0 1-1-1V4z"
                    />
                  </svg>
                </span>
                <span
                  id="shortdescription"
                  :text="shortDescription"
                  class="data-short-description-text"
                >
                  {{ shortDescription }}
                </span>
                <!-- <b-popover
                  v-if="descriptionProblem(shortDescription) !== 'Не указано'"
                  custom-class="p-2"
                  target="shortdescription"
                  placement="right"
                  triggers="hover focus"
                >
                  <div>{{ shortDescription }}</div>
                </b-popover> -->
                <help-popover
                  v-if="helpPopovers.shortDescription"
                  :message="helpPopovers.shortDescription"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                />
              </div>

              <div v-if="isShowDetailInfo">
                <span class="font-weight-bold data-device-label"
                  >Объект постановки задачи:</span
                >

                <div
                  v-if="device !== 'Не указано'"
                  @click="onSerialClick"
                  :class="{ 'fake-link': device !== 'Не указано' }"
                  class="data-device-text"
                >
                  {{ device }}
                  <help-popover
                    v-if="helpPopovers.theObjectTask"
                    :message="helpPopovers.theObjectTask"
                    class="ml-2 help-icon"
                    :is-inline-block="true"
                  />
                </div>
                <span v-else> Не указано</span>
              </div>

              <div>
                <span class="font-weight-bold data-author-label">Автор:</span>
                <span v-if="responsibleAuthor[0]" class="data-author-text">
                  {{
                    responsibleAuthor[0].firstName +
                      " " +
                      responsibleAuthor[0].lastName
                  }}
                  <help-popover
                    message="Автор задачи"
                    class="ml-2 help-icon"
                    :is-inline-block="true"
                  />
                </span>
              </div>

              <div @click="changeExecutorShow" style="cursor:pointer">
                <span class="font-weight-bold data-executor-label"
                  >Исполнитель:</span
                >
                <span v-if="responsibleCanWrite[0]" class="data-executor-text">
                  {{
                    responsibleCanWrite[0].firstName +
                      " " +
                      responsibleCanWrite[0].lastName
                  }}
                  <img src="../../assets/edit.svg" class="img-edit" alt="" />
                  <help-popover
                    v-if="helpPopovers.theExecutor"
                    :message="helpPopovers.theExecutor"
                    class="ml-2 help-icon"
                    :is-inline-block="true"
                  />
                </span>
              </div>

              <div
                v-if="isShowDetailInfo"
                @click="changeObserverShow"
                style="cursor:pointer"
              >
                <span class="font-weight-bold data-viewer-label"
                  >Наблюдатель:</span
                >
                <span class="data-viewer-text">
                  {{
                    responsibleObserver.length === 0
                      ? "не выбран"
                      : responsibleObserver
                  }}
                </span>
                <img src="../../assets/edit.svg" class="img-edit" alt="" />
                <help-popover
                  v-if="helpPopovers.theObserver"
                  :message="helpPopovers.theObserver"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                />
              </div>

              <div v-if="isShowDetailInfo && currentOrder.type">
                <span class="font-weight-bold data-type-label">
                  Тип задачи:
                </span>
                <span class="data-type-text">
                  {{ currentOrder.type.title }}
                </span>
                <help-popover
                  v-if="helpPopovers.theType"
                  :message="helpPopovers.theType"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                />
              </div>

              <div v-if="isShowDetailInfo">
                <span class="font-weight-bold data-budget-cents-label">
                  Бюджет задачи (рубли):
                </span>
                <span class="data-budget-cents-text">
                  {{ currentOrder.budget ? currentOrder.budget : "Не указано" }}
                </span>
                <help-popover
                  v-if="helpPopovers.theBudget"
                  :message="helpPopovers.theBudget"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                />
              </div>
              <div v-if="isShowDetailInfo">
                <span class="font-weight-bold data-budget-time-label">
                  Бюджет задачи (часы):
                </span>
                <span class="data-budget-time-text">
                  {{
                    currentOrder.estimatedTime
                      ? currentOrder.estimatedTime
                      : "Не указано"
                  }}
                </span>
                <help-popover
                  v-if="helpPopovers.theBudget"
                  :message="helpPopovers.theBudget"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                />
              </div>
            </div>

            <div class="info-items__history">
              <div v-if="currentOrder.deadline">
                <span class="font-weight-bold data-deadline-label">
                  Крайний срок выполнения:
                </span>
                <!-- {{ moment(currentOrder.deadline).format("lll") }} -->
                <span class="data-deadline-text">
                  {{ currentOrder.deadline | dateTimeWithoutLocale }}
                </span>
                <help-popover
                  v-if="helpPopovers.theDeadlines"
                  :message="helpPopovers.theDeadlines"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                />
              </div>
              <!--  v-if="currentOrder.texts && currentOrder.texts['Описание']" -->
              <div v-b-tooltip.focus>
                <span class="font-weight-bold data-description-label">
                  Описание:
                </span>
                <span
                  class="fake-link"
                  title="Копировать содержимое поля"
                  @click="copyText('description')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-files"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2
                    2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2
                    2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1
                    1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0
                    0 1-1 1H4a1 1 0 0 1-1-1V4z"
                    />
                  </svg>
                </span>
                <span
                  id="description"
                  :text="
                    currentOrder.texts['desc']
                      ? currentOrder.texts['desc']
                      : currentOrder.texts['Описание']
                  "
                  class="data-description-text"
                >
                  {{
                    currentOrder.texts["desc"]
                      ? currentOrder.texts["desc"]
                      : currentOrder.texts["Описание"]
                  }}
                </span>
                <!-- <b-popover
                  v-if="
                    (currentOrder.texts['desc'] ||
                      currentOrder.texts['Описание']) &&
                      description(
                        currentOrder.texts['desc']
                          ? currentOrder.texts['desc']
                          : currentOrder.texts['Описание']
                      ) !== 'Не указано'
                  "
                  custom-class="p-2"
                  target="description"
                  placement="left"
                  triggers="hover focus"
                >
                  <div>
                    {{
                      currentOrder.texts["desc"]
                        ? currentOrder.texts["desc"]
                        : currentOrder.texts["Описание"]
                    }}
                  </div>
                </b-popover> -->
                <help-popover
                  v-if="helpPopovers.fullDescription"
                  :message="helpPopovers.fullDescription"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                />
              </div>

              <div>
                <span
                  class="font-weight-bold data-admissioncriteria-label"
                  v-b-tooltip.focus
                >
                  Критерий приема задачи:
                </span>
                <span
                  class="fake-link"
                  title="Копировать содержимое поля"
                  @click="copyText('admissioncriteria')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-files"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2
                    2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2
                    2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1
                    1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0
                    0 1-1 1H4a1 1 0 0 1-1-1V4z"
                    />
                  </svg>
                </span>
                <span
                  id="admissioncriteria"
                  :text="admissionCriteria"
                  class="data-admissioncriteria-text"
                >
                  {{ admissionCriteria }}
                </span>
                <!-- <b-popover
                  v-if="
                    admissionCriteria &&
                      criteria(admissionCriteria) !== 'Не указано'
                  "
                  custom-class="p-2"
                  target="admissioncriteria"
                  placement="left"
                  triggers="hover focus"
                >
                  <div>
                    {{ admissionCriteria }}
                  </div>
                </b-popover> -->
                <help-popover
                  v-if="helpPopovers.theCriteria"
                  :message="helpPopovers.theCriteria"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                />
              </div>

              <div class="d-flex align-items-center flex-wrap">
                <span class="font-weight-bold mr-1">
                  <span class="data-createdat-label">
                    Задача создана
                  </span>
                </span>
                -
                <span class="data-createdat-text">
                  {{ moment(currentOrder.createdAt).format("lll") }}
                </span>
                <help-popover
                  v-if="helpPopovers.dateCreation"
                  :message="helpPopovers.dateCreation"
                  class="ml-2 help-icon"
                  :is-inline-block="true"
                />
              </div>

              <div
                class="d-flex align-items-center flex-wrap"
                @click="changePriorityShow"
                style="cursor: pointer"
              >
                <span class="font-weight-bold mr-1">
                  <span class="data-priority-label">
                    Приоритет:
                  </span>
                </span>
                <span class="data-priority-text">
                  {{ getPriorityTextByValue(currentOrder.priority) }}
                  <img src="../../assets/edit.svg" class="img-edit" alt="" />
                </span>
              </div>

              <!--  <div class="fake-link" v-if="isShowDetailInfo">
                Создать зависимую задачу >>
              </div> -->

              <div
                class="fake-link"
                @click="showHistoryModal"
                v-if="isShowDetailInfo"
              >
                История задачи >>
              </div>

              <!-- <div class="fake-link" v-if="isShowDetailInfo" @click="showModal">
                Вложения задачи >>
              </div>  -->
              <AttachmentModal v-if="isShowModal" @close="closeModal" />
            </div>
          </div>

          <div class="toggle">
            <span class="icon" @click="isShowDetailInfo = !isShowDetailInfo">
              <span :class="isShowDetailInfo ? 'up' : 'down'"></span>
            </span>
          </div>
          <div class="cast" @click="getOrderCast" title="Сделать слепок задачи">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              class="bi bi-files"
              viewBox="0 0 16 16"
            >
              <path
                d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"
              />
            </svg>
          </div>
        </div>
        <div class="chat-wrapper">
          <div class="full-height">
            <Chat
              :key="chatRouteId"
              :id="Number(currentOrder.chatId)"
              :show-top-panel="false"
              :is-use-old-chat="true"
              :isShowDetailInfo="isShowDetailInfo"
              :isShowSearchBar="isShowSearchBar"
              :chatKey="chatKey"
            />
          </div>
        </div>
      </div>

      <div
        v-else
        class="d-flex justify-content-center align-items-center align-self-center scroll-container"
        style="z-index:100000"
      >
        <b-spinner v-if="!hasOrderResponseError" />
        <b-alert show variant="danger" v-else>
          {{ orderResponseErrorText }}
        </b-alert>
      </div>
    </div>

    <ChangeObserverModal
      v-if="isShowChangeObserverModal"
      :responsibleViewers="responsibleViewers"
      :currentEmployee="responsibleObserver"
      :orderId="currentOrder.id"
      :responsibleExecutor="responsibleCanWrite"
      :currentEmployeeExecutor="responsibleCurrentEmployee"
      :currentExecutorId="currentExecutorId"
      @close="changeObserverHide"
      @update="getPermissions"
      @changeObservers="changeObservers"
    />

    <ChangeExecutorModal
      :responsible="responsibleCanWrite"
      :currentEmployee="responsibleCurrentEmployee"
      :orderId="currentOrder.id"
      @close="changeExecutorHide"
      @update="getPermissions"
      v-if="isShowChangeExecutorModal"
    />

    <OrderHistoryModal
      :statuses="statuses"
      :history="history.items"
      @close="closeHistoryModal"
      v-if="isShowHistoryModal"
      :order="this.currentOrder"
      :loading="isHistoryLoading"
    />

    <OrderStatusModal
      :statuses="statuses"
      :history="history.items"
      @close="closeStatusModal"
      @update="updateStatusModal"
      v-if="isShowStatusModal"
      :order="this.currentOrder"
    />

    <ChangePriorityModal
      v-if="isShowChangePriorityModal"
      @close="changePriorityHide"
      @save="saveAndReloadOrderInfo"
      :priority="this.currentOrder.priority"
    />

    <div v-if="isIntroPassed === 'false'" class="dark-intro-background">
      <div class="intro-tip-wrapper">
        <!-- <div class="intro-target"></div> -->
        <div v-if="!hasShopOrder" class="intro-tip p-2 w-50 text-center">
          Это страница с задачей. Здесь содержится вся нужная информация. В
          нижней части окно с чатом, там вы можете написать менеджеру в чат по
          задаче, а также получить ответ.<br /><br />
          Теперь мы можем посмотреть на список задач.<br />
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              type="button"
              class="btn-primary mt-4"
              @click="doPassIntro"
              >Спасибо</b-button
            >
          </div>
        </div>
        <div v-else class="intro-tip p-2 w-50 text-center">
          Это страница с задачей. Здесь содержится вся нужная информация. В
          нижней части окно с чатом, там вы можете написать менеджеру в чат по
          задаче, а также получить ответ.<br /><br />
          Теперь мы можем продолжить оформление заказа<br />
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              type="button"
              class="btn-primary mt-4"
              @click="doPassIntroAndGoShopping"
              >Спасибо</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </tepmplate-block>
</template>

<script>
import vuescroll from "vuescroll";
import { OrdersApi } from "@/function/request/apiV2";
import RequestManager from "@/function/request/RequestManager";
import { user } from "@/utils/users";
import { copyToClipboard } from "@/utils";
import Chat from "../../components/Chat/Chat.vue";
import TepmplateBlock from "../../components/TepmplateBlock";
import RequestListItem from "../../components/Request/RequestListItem";
import AttachmentModal from "@/components/Modal/AttachmentModal";
import ChangeExecutorModal from "@/components/Modal/ChangeExecutorModal";
import ChangeObserverModal from "@/components/Modal/ChangeObserverModal";
import OrderHistoryModal from "@/components/Modal/OrderHistoryModal";
import OrderStatusModal from "@/components/Modal/OrderStatusModal";
import ChangePriorityModal from "@/components/Modal/ChangePriorityModal";
import helpPopover from "@/components/HelpPopover";
import { dateTimeFromDateString } from "@/utils/filters";
import { removeTagsFromString } from "@/utils";
import { ORDER_PRIORITY_OPTIONS } from "@/app-config";
import { doPoseTip } from "@/utils";
import eventBus from "@/utils/event-bus";

export default {
  name: "Order",
  components: {
    OrderHistoryModal,
    OrderStatusModal,
    ChangeObserverModal,
    ChangeExecutorModal,
    AttachmentModal,
    TepmplateBlock,
    RequestListItem,
    vuescroll,
    Chat,
    helpPopover,
    ChangePriorityModal
  },

  data() {
    return {
      status_draft: require("../../../src/resource/img/status_draft.svg"),
      helpPopovers: {
        currentStatus: "Статус задачи",
        shortDescription: "Описание проблемы",
        theObjectTask: "Объект вопроса",
        theExecutor: "Исполняет задачу",
        theObserver: "Наблюдает за исполнением задачи",
        theType: "Тип задачи",
        theBudget: "Бюджет исполнения",
        theCriteria: "Критерии приёма задачи",
        dateCreation: "Дата создания задачи",
        theDeadlines: "Крайние сроки",
        fullDescription: "Описание задачи"
      },
      ordersApi: new OrdersApi(),
      currentOrder: null,
      items: [],
      count: 0,
      isLoading: false,
      ordersListIsLoading: false,
      isShowSearchBar: false,
      isShowModal: false,
      isShowHistoryModal: false,
      isShowStatusModal: false,
      isShowChangeExecutorModal: false,
      isShowChangeObserverModal: false,
      isShowChangePriorityModal: false,
      searchOrder: "",
      statuses: [],
      references: [],
      permissions: [],
      history: [],
      isShowDetailInfo: true,
      viewers: 0,
      hasOrderResponseError: false,
      orderResponseErrorText: "",
      currentExecutorId: undefined,
      isIntroPassed: undefined,
      chatKey: 0,
      isHistoryLoading: false,
      hasShopOrder: false
    };
  },
  computed: {
    getStatusColor() {
      let color;

      if (
        this.currentOrder?.status?.title === "UNDEFINED" ||
        !this.currentOrder?.status?.title
      ) {
        color = "status__not-exist";
      }

      if (this.currentOrder?.status.title === "Создан") {
        color = "yellow";
      }

      if (this.currentOrder?.status.title === "В обработке") {
        color = "orange";
      }

      if (this.currentOrder?.status.title === "В работе") {
        color = "blue";
      }

      if (this.currentOrder?.status.title === "На согласовании") {
        color = "purple";
      }

      if (this.currentOrder?.status.title === "Выполнен") {
        color = "green";
      }

      if (this.currentOrder?.status.title === "Закрыт") {
        color = "rifle-green";
      }

      return color;
    },

    admissionCriteria() {
      if (this.currentOrder?.texts["condition"])
        return this.currentOrder?.texts["condition"];

      if (this.currentOrder?.texts["Критерии приема"])
        return this.currentOrder?.texts["Критерии приема"];

      return "Не указано";
    },

    shortDescription() {
      /*  let bool1= true;
     bool1 = (this.currentOrder?.texts["Краткое описание"] || this.currentOrder?.texts["shortDesc"]);

      if (!bool1) return "Не указано";*/

      if (this.currentOrder?.texts["shortDesc"])
        return this.currentOrder?.texts["shortDesc"];

      if (this.currentOrder?.texts["Краткое описание"])
        return this.currentOrder?.texts["Краткое описание"];

      return "Не указано";
    },

    device() {
      const firstDevice = this.references?.devices?.[0];
      return firstDevice?.title ?? "Не указано";
    },

    chatRouteId() {
      return this.$route.params.id;
    },

    responsible() {
      return this.permissions?.filter(el =>
        el.roles?.includes("performer")
      )?.[0];
    },

    responsibleCanWrite() {
      return this.permissions
        ?.filter(
          el =>
            el.roles?.includes("canWrite") || el.roles?.includes("performer")
        )
        ?.map(el => el.user);
    },

    responsibleAuthor() {
      return this.permissions
        ?.filter(el => el.roles?.includes("owner"))
        ?.map(el => el.user);
    },

    responsibleViewers() {
      return this.permissions
        ?.filter(el => el.roles?.includes("viewer"))
        ?.map(el => el.user);
    },

    responsibleInfo() {
      if (this.responsible) {
        return user?.getFullName(this.responsible.user);
      }
      return "Не указано";
    },

    responsibleObserver() {
      if (!this.viewers) {
        let observers = this.permissions?.filter(el => {
          return el.roles?.includes("viewer");
        });

        observers = observers.map(
          el => `${el.user.firstName} ${el.user.lastName}`
        );
        observers = observers.join(", ");
        return observers;
      }
      return this.viewers;
    },

    responsibleCurrentEmployee() {
      return this.responsible?.userId ?? null;
    }
  },
  methods: {
    doPassIntro() {
      localStorage.setItem("isIntroPassed", "true");
      this.isIntroPassed = true;
      this.$router.push({ name: "RequestList" });
    },
    doPassIntroAndGoShopping() {
      localStorage.setItem("isIntroPassed", "true");
      this.isIntroPassed = true;
      this.$router.push({ name: "OrderPayment" });
    },
    copyText(elementId) {
      const copiedElement = document.getElementById(elementId);
      if (copiedElement) {
        copyToClipboard(copiedElement.getAttribute("text"));
        this.$bvToast.toast(
          `Содержимое поля успешно скопировано в буфер обмена`,
          {
            title: "Информация",
            solid: true,
            variant: "success",
            autoHideDelay: 3000,
            appendToast: false
          }
        );
      }
    },

    getPriorityTextByValue(value) {
      const foundPriorityOption = ORDER_PRIORITY_OPTIONS.find(
        opt => opt.value === value
      );
      return foundPriorityOption?.text || "Не указан";
    },

    getOrderCast() {
      const infoString = document.querySelector(".order-content__info")
        .innerHTML;
      const chatString = document.querySelector(".messages-wrapper").innerHTML;
      const historyStringSource = this.history.items.map(
        hItem =>
          `${dateTimeFromDateString(hItem.timestamp)} - ${hItem.description}`
      );
      const historyString = historyStringSource.join("\r\n");
      const cast = `${infoString}\r\n${chatString}\r\n${historyString}`;
      copyToClipboard(removeTagsFromString(cast));
      this.$bvToast.toast(
        `Содержимое поля успешно скопировано в буфер обмена`,
        {
          title: "Информация",
          solid: true,
          variant: "success",
          autoHideDelay: 3000,
          appendToast: false
        }
      );
    },

    titleLong(currentOrder) {
      let title = "(" + currentOrder.id + ")" + " " + currentOrder.title;
      return title;
    },

    nameTask(name) {
      if (name.length < 35) return name;
      else {
        let name1 = name.trim();
        name1 = name1.slice(0, 34) + "...";
        return name1;
      }
    },

    description(description) {
      if (!description) return "Не указано";
      let length = 0;
      if (this.isShowSearchBar) {
        length = 120;
      } else {
        length = 202;
      }
      if (description.length < length) return description;
      else {
        let description1 = description.trim();
        description1 = description1.slice(0, length - 1) + " ... ";
        return description1;
      }
    },

    descriptionProblem(shortDescription) {
      let length = 0;
      if (this.isShowSearchBar) {
        length = 39;
      } else {
        length = 80;
      }
      if (shortDescription.length < length) return shortDescription;
      else {
        let shortDescription1 = shortDescription.trim();
        shortDescription1 = shortDescription1.slice(0, length - 1) + "...";
        return shortDescription1;
      }
    },

    criteria(criteria) {
      let length = 0;
      if (this.isShowSearchBar) {
        length = 39;
      } else {
        length = 80;
      }
      if (criteria.length < length) return criteria;
      else {
        let criteria1 = criteria.trim();
        criteria1 = criteria1.slice(0, length - 1) + "...";
        return criteria1;
      }
    },

    nameTaskLong(name) {
      let length = 0;
      if (this.isShowSearchBar) {
        length = 130;
      } else {
        length = 207;
      }
      if (name.length < length) return name;
      else {
        let name1 = name.trim();
        name1 = name1.slice(0, length - 1) + "...";
        return name1;
      }
    },

    deviceId() {
      const firstDevice = this.references?.devices?.[0];
      return firstDevice?.id ?? "Не указано";
    },

    onSerialClick() {
      if (this.device !== "Не указано") {
        const id = this.deviceId();
        this.$router.push({ path: "/my-techique/" + id });
      }
    },
    async getReferences() {
      try {
        const response = await this.ordersApi.api.getOrderReferences(
          this.currentOrder.id,
          ["devices"]
        );
        this.references = response;
      } catch (e) {
        Error(e);
      }
    },

    showHistoryModal() {
      this.getHistory();
      this.isShowHistoryModal = !this.isShowHistoryModal;
    },

    showStatusModal() {
      //this.getHistory();
      this.isShowStatusModal = !this.isShowStatusModal;
    },

    changeObservers(observers) {
      this.viewers = observers
        .map(item => {
          return `${item.user.firstName} ${item.user.lastName}`;
        })
        .join(", ");
    },

    changeObserverShow() {
      this.isShowChangeObserverModal = true;
    },

    changeObserverHide() {
      this.isShowChangeObserverModal = false;
      this.getHistory();
    },

    changeExecutorShow() {
      this.isShowChangeExecutorModal = true;
    },

    changeExecutorHide() {
      this.isShowChangeExecutorModal = false;
      this.getHistory();
    },

    changePriorityShow() {
      this.isShowChangePriorityModal = true;
    },

    changePriorityHide() {
      this.isShowChangePriorityModal = false;
    },

    showModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    },

    closeHistoryModal() {
      this.isShowHistoryModal = false;
    },

    closeStatusModal() {
      this.isShowStatusModal = false;
    },

    updateStatusModal() {
      this.history = [];
      this.isShowHistoryModal = false;
      this.getCurrentOrder();
      this.getHistory();
    },

    getOrdersByDeviceId() {
      this.items = [];
      this.getOrders();
    },

    async getHistory() {
      this.isHistoryLoading = true;
      await this.ordersApi.api
        .getOrderHistory(this.chatRouteId, {
          type: "changeStatus"
        })
        .then(res => {
          this.history = res;
        });
      await this.ordersApi.api
        .getOrderHistory(this.chatRouteId, {
          type: "permissions"
        })
        .then(res => {
          this.history = {
            count: this.history.count + res.count,
            items: this.history.items.concat(res.items)
          };
        });
      await RequestManager()
        .chatApi.listChatMessages(this.currentOrder.chatId, {})
        .then(res => {
          this.history = {
            count: this.history.count + res.count,
            items: this.history.items.concat(res.items)
          };
        });
      this.history.items.sort(function(o1, o2) {
        return new Date(o1.timestamp) - new Date(o2.timestamp);
      });
      this.isHistoryLoading = false;
    },

    async getPermissions(executorId) {
      this.permissions = await this.ordersApi.api.getOrderPermissions(
        this.chatRouteId
      );
      this.currentExecutorId = executorId;
    },

    async getStatuses() {
      this.statuses = await this.ordersApi.api.getOrderStatuses();
    },

    async getCurrentOrder() {
      try {
        this.isLoading = true;
        this.currentOrder = await this.ordersApi.api.getOrder(
          this.$route.params.id
        );
        this.hasOrderResponseError = false;
        this.orderResponseErrorText = "";
      } catch (e) {
        this.orderResponseErrorText =
          e.body.message === "Access denied."
            ? "У вас нет доступа к данной задаче"
            : "Что-то пошло не так";
        this.hasOrderResponseError = true;
        throw Error(e);
      } finally {
        this.isLoading = false;
      }
    },

    async getOrders() {
      /*  this.ordersListIsLoading = true;
      try {
        const { count, items } = await this.ordersApi.api.listOrders({
          limit: 100,
          companyId: this.$store.getters.currentUser.company,
          ordersIds: this.searchOrder
            ? [this.searchOrder.toString()]
            : undefined,
           statusIds:  ["1", "2", "3", "5", "6"]
        });
        this.count = count;
        this.items = items?.filter(el => el.id !== this.chatRouteId);
      } catch (e) {
        throw Error(e);
      } finally {
        this.ordersListIsLoading = false;
      }
    } */

      /*   Date.prototype._addHours = function(h) {
        this.setHours(this.getHours() + h);
        return this;
      };

      Date.prototype._addMinutes = function(m) {
        this.setMinutes(this.getMinutes() + m);
        return this;
      };

      let maxDate = this.created_at_max_not_format
        ? new Date(this.created_at_max_not_format)._addHours(23)._addMinutes(59)
        : undefined;

      this.listParams.created_at_min = this.created_at_min_not_format
        ? new Date(this.created_at_min_not_format)
        : undefined;
      this.listParams.created_at_max = maxDate; */

      // this.listParams.serialNumber = this.serialNumber;

      /* console.log(
        "this.$route.params.listParams",
        this.$route.params.listParams
      );*/

      this.spinner = true;
      try {
        const { items, count } = await this.ordersApi.api.listOrders({
          limit: 100,
          order: "DESC",
          sort: "updatedAt",
          statusIds: this.$route.params.listParams?.statusIds
            ? this.$route.params.listParams?.statusIds
            : ["1", "2", "3", "5", "6"],
          createdAtMin: this.$route.params.listParams?.created_at_min,
          createdAtMax: this.$route.params.listParams?.created_at_max,
          deviceName: this.searchOrder
            ? this.searchOrder
            : this.$route.params.listParams?.serialNumber,
          deviceOfficeId: this.$route.params.listParams?.office_id,
          authorId:
            this.$route.params.listParams?.selectedType === "Author"
              ? this.$route.params.listParams?.employee_id
              : undefined,
          performerId:
            this.$route.params.listParams?.selectedType === "Executor"
              ? this.$route.params.listParams?.employee_id
              : undefined
        });
        //   console.log("items in order", items);
        //   console.log("count in order", count);
        this.count = count;
        this.items = items.filter(el => el.id !== this.chatRouteId);
        /*  this.ordersCount = count;
          this.orders = [...this.orders, ...items];
          this.orders = this.uniqBy(this.orders, "id");*/
      } catch (e) {
        throw Error(e);
      } finally {
        this.spinner = false;
      }
    },

    async saveAndReloadOrderInfo(priority) {
      this.currentOrder.priority = priority;
      this.isShowChangePriorityModal = false;
      await this.ordersApi.api.editOrder(
        this.currentOrder.id,
        this.currentOrder
      );
    }
  },

  async created() {
    this.hasShopOrder = !!localStorage.getItem("cart_products");
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
    this.searchOrder = this.$route.params.listParams?.serialNumber;

    this.getPermissions();
    this.getStatuses();
    await this.getCurrentOrder();
    this.getOrders();
    this.getReferences();
    // this.getHistory();
    if (this.isIntroPassed === "false") {
      doPoseTip(".all-title-page", "bottom");
    }
  },

  mounted() {
    eventBus.$on("chat_new-message", message => {
      if (this.currentOrder.chatId === message.payload.id) {
        this.chatKey += 1;
      }
    });
  },

  watch: {
    async chatRouteId() {
      this.getPermissions();
      this.getStatuses();
      await this.getCurrentOrder();
      this.getOrders();
      this.getReferences();
    },

    responsibleCanWrite(newValue) {
      this.currentExecutorId = parseInt(newValue[0].id);
    }
  }
};
</script>

<style scoped lang="scss">
.scroll-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 90%;

  .__vuescroll {
    height: calc(100vh - 375px) !important;
    width: 100% !important;
  }
}

.serial_number {
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  color: blue;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.status__simple {
  color: #357af6;
  font-weight: bold;
}

.status__created {
  color: #f2be41;
  font-weight: bold;
}

.order {
  margin-top: 20px;
  display: flex;
  width: 98%;
  gap: 10px;
  //min-height: calc(100vh - 120px);
  justify-content: space-between;

  &-content {
    width: 98%;
  }

  &-search {
    display: flex;
    justify-content: space-between;
    background-color: var(--main-card-color);
    max-width: 395px;
    padding: 15px 10px;
    border-radius: 5px;

    &__content {
      margin-right: 7px;
      max-width: 335px;
    }

    &__bar {
      margin-bottom: 20px;
      width: 335px;
    }

    .scroller {
      .__vuescroll {
        height: 87vh !important;
        max-height: calc(100vh - 200px) !important;
        width: 100% !important;
      }
    }

    .orders-list-wrapper {
      overflow: auto;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
      padding-right: 20px;
    }

    &__toggle,
    .toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #2c698c;
      transition: all 0.5s ease;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px #2c698c solid;
        border-radius: 50%;
        width: 35px;
        height: 35px;
      }

      .min:before {
        content: "<";
      }
      .max:before {
        content: ">";
      }

      .up:before {
        content: "<";
      }

      .down:before {
        content: ">";
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 15px;

    &__info {
      background-color: var(--main-card-color);
      padding: 10px 25px;
      border-radius: 5px;
      position: relative;
    }
  }
}

.info-title {
  overflow: hidden;
  white-space: nowrap;

  h1 {
    text-overflow: ellipsis;
    font-size: 20px;
  }
}

.info {
  position: relative;

  &-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    font-size: 15px;

    &__description > * {
      margin-bottom: 8px;
    }

    &__history > * {
      margin-bottom: 8px;
    }
  }

  .toggle {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .cast {
    position: absolute;
    top: 20px;
    right: 60px;
    cursor: pointer;
    color: #2c698c;
  }
}

.toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #2c698c;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px #2c698c solid;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: all 0.5s ease;
    position: relative;

    .up,
    .down {
      position: absolute;
      display: block;
      border-left: 2px #2c698c solid;
      border-top: 2px #2c698c solid;
      width: 15px;
      height: 15px;
    }

    .up {
      transform: rotate(45deg);
      top: 12px;
    }

    .down {
      transform: rotate(-135deg);
      top: 6px;
    }
  }

  .min:before {
    content: "<";
  }
  .max:before {
    content: ">";
  }
}

.order-wrapper {
  height: calc(100% - 20px);
}

.order-status {
  color: #f2be41;
  display: inline-flex;
  align-items: center;
}

.point {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: white;
  border: 2px #f2be41 solid;
  border-radius: 50%;
}

.form-control {
  background-color: var(--main-card-color);
}

.img-edit {
  filter: var(--filter-for-svg);
}

.status__not-exist {
  color: gray;
}

.status__exist {
  color: #f2be41;
  font-weight: bold;
}

.yellow {
  color: #fbbd3b;
}
.blue {
  color: #2378f9;
}
.orange {
  color: #fb823b;
}
.purple {
  color: #7c3bfb;
}
.green {
  color: #2dc44f;
}
.rifle-green {
  color: #116e25;
}

.full-height {
  height: 100%;
}
.scroll-y {
  height: auto;
  max-height: 100%;
  overflow-y: auto;
}

.flex-1 {
  flex: 1;
}

.chat-wrapper {
  flex: 1;
  max-height: 100%;
  overflow-y: hidden;
  background-color: var(--bg-chat-gradient);
  background-image: var(--chat-gradient);
}

#title
/* #admissioncriteria,
#shortdescription,
#description */ {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#title {
  line-height: 1.7em;
}

/* #description {
  -webkit-line-clamp: 4;
} */

.popover-body > div,
#description,
#shortdescription,
#admissioncriteria {
  white-space: pre-line !important;
}

.slim-order-item {
  max-width: 335px;
}
</style>
